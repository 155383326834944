import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import ThemeToggler from "../utils/themeToggler";
import CustomButton from "../utils/customButton";
import { icons } from "../utils/icons";

const Footer = ({ setTheme }) => {
  return (
    <Stack
      component="footer"
      className="container"
      sx={{ alignItems: "center", padding: "2.5rem 0", gap: "1.5rem" }}
    >
      <Box width="112px">
        <img
          width={"100%"}
          src={require("../assets/images/logo11.png")}
          alt="Waiter"
        />
      </Box>
      <Stack
        component="ul"
        direction={{ xs: "column", lg: "row" }}
        sx={{ fontWeight: "500", gap: "2rem", alignItems: "center" }}
      >
        <li>
          <NavLink>تواصل معنا</NavLink>
        </li>
        <li>
          <a href="/return_and_exchange">سياسة الإستبدال والإرجاع</a>
        </li>
        <li>
          <a href="/order">طريقة الطلب</a>
        </li>
        <li>
          <a href="/#prices">الأسعار</a>
        </li>
        <li>
          <a href="/solutions">الحلول</a>
        </li>
        <li>
          <a href="/">الرئيسية</a>
        </li>
      </Stack>

      <Box
        component="span"
        sx={{
          backgroundColor: "var(--very-light-gray)",
          height: "1px",
          width: "100%",
        }}
      ></Box>

      <Box
        component="span"
        sx={{
          display: "flex",
          gap: "8px",
        }}
      >
        <CustomButton
          style={{ padding: "8px", minWidth: "auto" }}
          text={icons.whatsapp}
          textcolor="var(--blue)"
          bgcolor=""
          paddingsize=" 0px"
          onClick={() =>
            window.open(
              "https://wa.me/966537206167",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <CustomButton
          style={{ padding: "8px", minWidth: "auto" }}
          text={icons.tiktok}
          textcolor="var(--blue)"
          bgcolor=""
          paddingsize=" 0px"
          onClick={() =>
            window.open(
              "#",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <CustomButton
          style={{ padding: "8px", minWidth: "auto" }}
          text={icons.instagram}
          textcolor="var(--blue)"
          bgcolor=""
          paddingsize=" 0px"
          onClick={() =>
            window.open(
              "https://www.instagram.com/waiter.sa?igsh=MWFzanluM3dyM3AxOQ==",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <CustomButton
          style={{ padding: "8px", minWidth: "auto" }}
          text={icons.x}
          textcolor="var(--blue)"
          bgcolor=""
          paddingsize=" 0px"
          onClick={() =>
            window.open(
              "https://x.com/waiter_sa?s=21&t=cFk2YdnMm0L4FHhz43h9Kg",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
      </Box>
      <Box
        component="span"
        sx={{
          display: "block",
        }}
      >
        <ThemeToggler setTheme={setTheme} />
      </Box>
      <Typography
        variant="body1"
        sx={{ fontWeight: "500", color: "var(--gray)" }}
      >
        جميع الحقوق محفوظة لشركة ويتر © 2023
      </Typography>
    </Stack>
  );
};

export default Footer;
