// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .main {
	width: 100vw;
	min-height: 100vh;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	z-index: -1;
	top: 0;
	left: 0;
	overflow: hidden;
}

.gradient {
	opacity: 0.5;
}

.circle {
	border-radius: 50%;
	position: absolute;
	filter: blur(250px);
}

.green-circle {
	width: 736.825px;
	height: 736.825px;
	background: #cff85b;
	top: -50%;
	left: 40%;
}
.blue-circle {
	width: 667.608px;
	height: 667.608px;
	background: #3efbe0;
	top: -57%;
	right: 45%;
} */

/* div.open {
	height: 180px;
}

@media (min-width: 625px) and (max-width: 900px) {
	.MuiBox-root.css-1v1cfkl {
		background-size: contain !important;
	}
} */
.App {
  /* position: relative; */
  background: var(--bg-color);
}
@media (max-width: 768px) {
  div.open {
    height: 300px;
  }
}
@media (max-width: 992px) {
  div.open {
    height: 200px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAqCG;;AAEH;;;;;;;;GAQG;AACH;EACE,wBAAwB;EACxB,2BAA2B;AAC7B;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* .main {\n\twidth: 100vw;\n\tmin-height: 100vh;\n\tposition: absolute;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpointer-events: none;\n\tz-index: -1;\n\ttop: 0;\n\tleft: 0;\n\toverflow: hidden;\n}\n\n.gradient {\n\topacity: 0.5;\n}\n\n.circle {\n\tborder-radius: 50%;\n\tposition: absolute;\n\tfilter: blur(250px);\n}\n\n.green-circle {\n\twidth: 736.825px;\n\theight: 736.825px;\n\tbackground: #cff85b;\n\ttop: -50%;\n\tleft: 40%;\n}\n.blue-circle {\n\twidth: 667.608px;\n\theight: 667.608px;\n\tbackground: #3efbe0;\n\ttop: -57%;\n\tright: 45%;\n} */\n\n/* div.open {\n\theight: 180px;\n}\n\n@media (min-width: 625px) and (max-width: 900px) {\n\t.MuiBox-root.css-1v1cfkl {\n\t\tbackground-size: contain !important;\n\t}\n} */\n.App {\n  /* position: relative; */\n  background: var(--bg-color);\n}\n@media (max-width: 768px) {\n  div.open {\n    height: 300px;\n  }\n}\n@media (max-width: 992px) {\n  div.open {\n    height: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
