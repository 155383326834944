import { ReactComponent as Star } from "../assets/icons/Star.svg";
import { ReactComponent as GreenCursor } from "../assets/icons/green-cursor.svg";
import { ReactComponent as PurpleCursor } from "../assets/icons/purple-cursor.svg";
import { ReactComponent as Dotted } from "../assets/icons/dotted.svg";
import { ReactComponent as DottedLine } from "../assets/icons/dotted-line.svg";
import { ReactComponent as SmallStar } from "../assets/icons/small-star.svg";
import { ReactComponent as Box } from "../assets/icons/box.svg";
import { ReactComponent as Location } from "../assets/icons/location.svg";
import { ReactComponent as Market } from "../assets/icons/market.svg";
import { ReactComponent as Person } from "../assets/icons/person.svg";
import { ReactComponent as Wifi } from "../assets/icons/wifi.svg";
import { ReactComponent as Cart } from "../assets/icons/cart.svg";
import { ReactComponent as Doc } from "../assets/icons/document.svg";
import { ReactComponent as Wallet } from "../assets/icons/wallet.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../assets/icons/minus.svg";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/arrow-up.svg";
import { ReactComponent as Sun } from "../assets/icons/sun.svg";
import { ReactComponent as Moon } from "../assets/icons/moon.svg";
import { ReactComponent as Whatsapp } from "../assets/icons/ic--baseline-whatsapp.svg";
import { ReactComponent as Tiktok } from "../assets/icons/ic--baseline-tiktok.svg";
import { ReactComponent as Instagram } from "../assets/icons/mdi--instagram.svg";
import { ReactComponent as X } from "../assets/icons/pajamas--twitter.svg";

export const icons = {
  star: <Star />,
  gc: <GreenCursor />,
  pc: <PurpleCursor />,
  dotted: <Dotted />,
  dottedLine: <DottedLine />,
  smallStar: <SmallStar />,
  box: <Box />,
  location: <Location />,
  market: <Market />,
  person: <Person />,
  wifi: <Wifi />,
  cart: <Cart />,
  doc: <Doc />,
  Wallet: <Wallet />,
  plus: <Plus />,
  minus: <Minus />,
  menu: <Menu />,
  close: <Close />,
  up: <ArrowUp />,
  sun: <Sun />,
  moon: <Moon />,
  whatsapp: <Whatsapp />,
  tiktok: <Tiktok />,
  instagram: <Instagram />,
  x: <X />,
};
