export const packages = [
  {
    id: 1,
    name: "باقة التطبيق",
    price: [
      { type: "شهريا", money: 399 },
      { type: "سنويا", money: 3990 },
    ],
    features: [
      "تطبيق على ابل ستور وقوقل بلاي",
      "امكانية اضافة توصيل بدون رسوم من طرفنا",
      "الاستلام داخل الفرع",
      "اضافة اكثر من فرع",
      "بيانات عميلك لديك",
      "التحكم بالفواتير",
      "عدد طلبات لامحدود",
      "عدد فواتير لامحدوده",
      "الدفع الالكتروني ",
      "اضافة رسوم على سعر التوصيل",
      "تحديد اقصى مسافه يمكن التوصيل لها",
      "امكانية ربط التطبيق مع اجهزة كاشير ويتر",
    ],
    special: false,
  },

  {
    id: 2,
    name: "باقة الكاشير",
    price: [
      { type: "مدى الحياة", money: 2999 },
      { type: "مدى الحياة", money: 2999 },
      //   { type: "سنويا", money: 299 },
    ],
    features: [
      "الاشتراك مدى الحياة ( الدفع مره واحده )",
      "يشمل جهاز ايباد ابل مع نظام ويتر كاشير",
      "طابعتين",
      "يمكنك طلب نقاط بيع مجاناً مقدم من أحد البنوك",
      "صندوق نقود",
      "العوده اي وقت للفواتير",
      "امكانية اضافة جهاز نقاط بيع مجانًا من بنك الرياض",
      "اضافة موظفين ولكل موظف حساب خاص به",
      "تحديد صلاحيات الموظفين",
      "فواتير لانهائيه",
      "تطبيق سريع وسلس",
      "التحكم بالطاولات",
      "اضافة المنتجات من الكاشير",
      "امكانية ربطه مع ويتر تطبيق",
    ],
    special: false,
  },
  {
    id: 3,
    name: "الباقه المتكامله 🌟",
    price: [
      { type: "مدى الحياة", money: 3399 },
      { type: "مدى الحياة", money: 3399 },
      //   { type: "سنويا", money: 299 },
    ],
    features: [
      "	جميع المميزات في باقة التطبيق + الكاشير",
      "احصائيات الكاشير والتطبيق في مكان واحد",
      "استلام الطلب على الفرع مثلًا : لو عندك فرع A وفرع B والعميل طلب من التطبيق على الفرع B سيذهب الطلب لكاشير الفرع B ويمكن قبوله او رفضه من الموظف في الكاشير او من خلال صفحة المالك",
    ],
    special: true,
  },
];
