import { icons } from "../utils/icons";

export const advantages = [
  {
    id: 1,
    title: "توصيل الطلبات.",
    content:
      "امكانية اضافة توصيل الطلبات من مطعمك بدون اي عموله اضافيه ومع رسوم توصيل تتحكم بها",
    icon: icons.box,
  },
  {
    id: 2,
    title: "تحديد المواقع.",
    content: " بإمكانك تحديد موقع العميل بكل سهولة.",
    icon: icons.location,
  },
  {
    id: 3,
    title: "اضافة اكثر من فرع.",
    content: "يمكنك اضافة اكثر من فرع لمتجرك الخاص.",
    icon: icons.market,
  },
  {
    id: 4,
    title: "بيانات العميل.",
    content: "العميل عميلك، لديك بياناته كاملة.",
    icon: icons.person,
  },
  {
    id: 5,
    title: "سهولة الدفع الإلكتروني.",
    content: "الدفع الالكتروني وابل باي يفعل تلقائي.",
    icon: icons.wifi,
  },
  {
    id: 6,
    title: "تتبع كل شيء من مكان واحد فقط.",
    content: "ربط الكاشير مع التطبيق وتتبع الاحصائيات من مكان واحد.",
    icon: icons.cart,
  },
  {
    id: 7,
    title: "يمكنك التحكم في الإحصائيات.",
    content: "اضافة اكثر من موظف واحصائيات للموظفين مثل عدد الفواتير المصدره.",
    icon: icons.doc,
  },
  {
    id: 8,
    title: "مدفوعاتك خلال 48 ساعة.",
    content: "تحويل المدفوعات خلال 48 ساعه.",
    icon: icons.Wallet,
  },
];
