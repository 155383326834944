import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
import RootLayout from "./pages/rootLayout";
import Home from "./components/home";
import Solutions from "./components/solutions";
import Order from "./components/order";
import Return from "./components/Return";
import { useEffect } from "react";
import { initializeTagManager } from "./utils/gtm";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/order" element={<Order />} />
      <Route path="/return_and_exchange" element={<Return />} />
    </Route>
  )
);

function App() {
  useEffect(() => {
    initializeTagManager();
  }, []);
  return <RouterProvider router={router} />;
}

export default App;

// App.js
