// gtm.js
import TagManager from "react-gtm-module";

const gtmId1 = "GTM-TCS2TCD6"; // Replace with your first GTM ID
const gtmId2 = "GTM-NKLLSRVH";  // Replace with your second GTM ID

export const initializeTagManager = () => {
  TagManager.initialize({ gtmId: gtmId1 });
  TagManager.initialize({ gtmId: gtmId2 });
};
