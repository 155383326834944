import React from "react";

import { Box, Typography } from "@mui/material";

import Header from "../utils/header";

const Return = () => {
  return (
    <Box id="retrun_and_exchange">
      <Box className="container set-padding " sx={{ marginTop: "3rem" }}>
        <Header text="سياسة الاستبدال والاسترجاع" right={true} left={false} />
        <Box
          sx={{
            position: "relative",
            marginTop: "2.5rem",
            color: "var(--black)",
          }}
        >
          <Typography
            variant="body1"
            fontSize="1.25rem"
            sx={{ textAlign: "center" }}
          >
            التطبيق لانه خدمه رقميه وحسب الطلب فلايوجد له ارجاع، الا في حال
            هنالك مشاكل من طرفنا فسيتم تعويض العميل باسترجاع قيمة الاشتراك
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Return;
